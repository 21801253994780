.anw-avatar-shadow {
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  border-radius: 0.625rem;

  .anw-avatar {
    border-radius: 0.625rem;
    border: #c2c2c2 1px solid;
  }
  .anw-avatar-profile-video {
    min-width: 145px;
    min-height: 145px;
    border-radius: 0.625rem 0.625rem 0px 0px !important;
    border-top: #c2c2c2 1px solid !important;
    border-right: #c2c2c2 1px solid !important;
    border-left: #c2c2c2 1px solid !important;
  }

  .anw-profile-video-badge {
    &:focus-within,
    &:hover {
      .anw-btn-gradient-icon {
        transform: translateY(-0.125rem) scale(1.1);
      }
    }

    .anw-btn-gradient {
      background-image: linear-gradient(45deg, #e95d0f, #e50076);
      border: none !important;
      border-bottom-left-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
      border-top: 1px solid #fff !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      color: #fff;
      display: flex;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.03rem;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      position: relative;
      width: 100%;
      z-index: 3;
      grid-column-gap: 0.5rem;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      align-items: center;
      justify-content: flex-end;

      .anw-btn-gradient-icon {
        position: absolute;
        bottom: 0.25rem;
        left: 0.5rem;
        height: 3rem;
        width: 3rem;
        transition-duration: 0.15s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }
}
